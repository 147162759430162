/* import only the necessary Bootstrap files */
@import "bootstrap/functions";
@import "bootstrap/variables";

/* button variable */
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.7rem;
$btn-font-size: 0.875rem;

/* Small */
$btn-padding-x-sm: 1.5rem;
$btn-padding-y-sm: 0.4rem;
$btn-font-size-sm: 0.875rem;

/* Large */
$btn-padding-x-lg: 1.5rem;
$btn-padding-y-lg: 0.925rem;
$btn-font-size-lg: 1rem;

// Small: 30px
// Medium: 45px
// Large: 55px

/* END: button variable */

/* change the color */
$theme-colors: (
	"danger-dark": #a94442,
	"light": #f4f4f4,
	"light-dark": $gray-300,
	success: #85d6af
);
/* END: change the color */

$font-path: "/assets/fonts/";

// @font-face {
// 	font-family: "Sanchezregular";
// 	src: url("#{$font-path}Sanchezregular.eot");
// 	src: url("#{$font-path}Sanchezregular.eot?#iefix") format("embedded-opentype"),
// 		url("#{$font-path}Sanchezregular.woff") format("woff"),
// 		url("#{$font-path}Sanchezregular.ttf") format("truetype"),
// 		url("#{$font-path}Sanchezregular.svg#Sanchezregular") format("svg");
// 	font-weight: normal;
// 	font-style: normal;
// }

/* Custom Font */
$font-family-sanchez: Arial, Helvetica, sans-serif;
$font-family-brandon: "brandon-grotesque", Arial, Helvetica, sans-serif;
/* END: Custom Font */

$font-family-base: $font-family-sanchez;
$component-active-bg: theme-color("black");

/* radio checked */
$custom-control-indicator-radio-color: $component-active-bg;
$custom-radio-indicator-border-radius: $border-radius;
$custom-radio-indicator-icon-checked: str-replace(
	url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-2 -2 4 4'%3e%3crect rx='1' x='-2' y='-2' width='8' height='8' fill='#{$custom-control-indicator-radio-color}'/%3e%3c/svg%3e"),
	"#",
	"%23"
);

$font-size-md: $font-size-base * 1.125 !default;
